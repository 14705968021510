import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService } from '../core/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | boolean | UrlTree {
    const companyId = route.paramMap.get('company_id');
    return this.authService.isLoggedIn(companyId).pipe(
      take(1),
      map((loggedIn: boolean) => {
        return loggedIn || this.router.createUrlTree(['login'], {queryParams: {returnUrl: state.url}});
      })
    );
  }
}
