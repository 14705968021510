import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ApiService,
  GET_COMPANY_DETAILS,
  IPagitationSource
} from 'src/app/core/api.service';
import { Department } from 'src/app/shared/interfaces/core.interface';
import { Company } from 'src/app/shared/model/company';

export type IResponseDepartments = IPagitationSource & {
  departments: Department[];
};

export interface IQueryParams {
  page?: number;
  query?: string;
}

@Injectable({
  providedIn: 'root'
})
export class CompanyV2Service {
  constructor(private api: ApiService) {}

  getCompanyDetails(companyId: string): Observable<Company> {
    return this.api.get<Company>(GET_COMPANY_DETAILS(companyId));
  }
}
