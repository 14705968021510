import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import {
  mergeMap,
  take
} from 'rxjs/operators';
import { SessionService } from 'src/app/core/session.service';
import { AUTHENTICATED_DOMAINS } from '../../environments/environment';
import { AuthService } from './auth.service';

// case insensitive check against config and value
const startsWithAny = (arr: string[] = []) => (value = '') => {
  return arr.some(test => value.toLowerCase().startsWith(test.toLowerCase()));
};

const isAbsoluteURL = startsWithAny(['http', '//']);

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private sessionService: SessionService,
    private authService: AuthService
  ) {}

  intercept(
    request: HttpRequest<any>, next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let hostname = request.url;
    let authenticated: boolean;

    if (isAbsoluteURL(request.url)) {
      hostname = (new URL(request.url)).hostname;
      authenticated = AUTHENTICATED_DOMAINS.some(domain => hostname.includes(domain));
    } else {
      authenticated = true;
    }

    if (authenticated && !request.headers.has('Authorization')) {
      return combineLatest([
        this.sessionService.token$,
        this.sessionService.currentCompany$,
      ]).pipe(
        take(1),
        mergeMap(([token, currentCompany]) => {
          const organizationId = currentCompany?.organization_id;
          const setHeaders = {
            Authorization: `Bearer ${token}`,
            'CC-Organization-Id': organizationId?.toString() || ''
          };

          if (!!token) {
            request = request.clone({ setHeaders });
          }

          return next.handle(request);
        })
      );
    } else {
      return next.handle(request);
    }
  }
}
