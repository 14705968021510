<header class="header">
  <div class="d-flex align-items-center gap-16">
    <div
      class="sm-hide"
      *ngIf="sessionService.isLoggedIn$ | async"
    >
      <button mat-icon-button (click)="sidebarService.toggleMenu()">
        <mat-icon class="header__menu">menu</mat-icon>
      </button>
    </div>
    <h2 class="header__title">{{titleService.title$ | async}}</h2>
    <ng-content select="[headerExtension]"></ng-content>
  </div>
  <div class="d-flex align-items-center gap-16">
    <ng-content select="[headerButtons]"></ng-content>
  </div>
</header>
