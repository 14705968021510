import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, shareReplay, tap } from 'rxjs/operators';
import { CompanyV2Service } from '../services/company-v2.service';
import { UserService } from '../services/user.service';
import { Company } from '../shared/model/company';
import { Permissions, User } from '../shared/model/user';
import { ApiService, USER_PERMISSIONS_API } from './api.service';
import { AuthService, DEFAULT_EXPIRATION_INTERVAL_HOURS } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  public token$: Observable<string>;
  public authUser$: Observable<firebase.User>;
  public isLoggedIn$ = new Observable<boolean>();

  public loggedInUser$: Observable<User> = new Observable<User>();
  public companyUser: User;
  
  public permissions$: Observable<Permissions> = of(new Permissions());
  public permissions: Permissions = new Permissions();
  
  private _currentCompanyId$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public readonly currentCompanyId$ = this._currentCompanyId$.asObservable().pipe(distinctUntilChanged((a, b) => a === b));
  public currentCompanyId: string;

  private _currentCompany$: BehaviorSubject<Company> = new BehaviorSubject<Company>(null);
  public readonly currentCompany$ = this._currentCompany$.asObservable();

  constructor(
    private api: ApiService,
    private authService: AuthService,
    private userService: UserService,
    private companyV2Service: CompanyV2Service,
  ) {
    this.token$ = this.currentCompanyId$.pipe(
      mergeMap((currentCompanyId) => this.authService.getToken(currentCompanyId)),
    );
    
    this.authUser$ = this.currentCompanyId$.pipe(
      mergeMap((currentCompanyId) => this.authService.getUser(currentCompanyId)),
    );

    this.isLoggedIn$ = this.authUser$.pipe(
      map((user) => !!user && !user.isAnonymous)
    );
    
    this.currentCompanyId$.subscribe((companyId) => {
      this.currentCompanyId = companyId;
    });

    this.permissions$ = this.currentCompany$.pipe(
      filter((currentCompany) => !!currentCompany),
      mergeMap((currentCompany) => this.api.get<Permissions>(USER_PERMISSIONS_API('company', currentCompany.id))),
      shareReplay(),
      tap((permissions) => this.permissions = permissions),
    );

    this.currentCompany$.pipe(
      filter((company) => !!company),
      mergeMap((company) => this.userService.getCompleteProfile(company.id))
    ).subscribe((user) => this.companyUser = user);

    this.authUser$.subscribe((user) => {
      if (user) {
          user.getIdTokenResult().then((decodedToken) => {
            let shouldLogout = false;

            if (decodedToken.claims?.expire_time) {
              if (!this.authService.isLoginRecent(decodedToken.claims.expire_time)) {
                shouldLogout = true;
              }
            } else {
              let expirationInterval = DEFAULT_EXPIRATION_INTERVAL_HOURS;
              if (decodedToken.claims?.expiration_interval) {
                expirationInterval = decodedToken.claims.expiration_interval;
              }
              if (!this.authService.isLoginRecent_deprecated(user.metadata.lastSignInTime, expirationInterval)) {
                shouldLogout = true;
              }
            }

            if (shouldLogout) {
              console.warn('Old credentials. Will logout');
              this.authService.logout();
            }
          });
      }
    });
    
    this.loggedInUser$ = this.authUser$.pipe(
      mergeMap((firebaseUser) => {
        const getUser = firebaseUser ? this.userService.getProfile() : of(null);
        return combineLatest([of(firebaseUser), getUser]);
      }),
      map(([firebaseUser, user]: [firebase.User, User]) => {
        if (user) {
          user.last_sign_in = firebaseUser.metadata.lastSignInTime;
        }
        return user;
      }),
      shareReplay(1)
    );
  }

  changeCurrentCompany(companyId: string): Observable<boolean> {
    if (companyId === this.currentCompanyId) {
      return of(true);
    }

    return this.authService.isLoggedIn(companyId).pipe(
      mergeMap((isLoggedIn) => {
        if (isLoggedIn) {
          return this.companyV2Service.getCompanyDetails(companyId).pipe(
            map((company) => {
              if (company) {
                this._currentCompanyId$.next(companyId);
                this._currentCompany$.next(company)
                return true;
              }
              return false;
            })
          );
        }
        return of(false);
      }),
    )
  }
}
